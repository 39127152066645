@charset 'UTF-8';
@use './variables' as form;
@use './functions' as func;
@use 'sass:math';



// ファイルアップロード
@mixin file-selector-button( $x: 1em, $y: 1em )
{
  background-color: form.$gray-100;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: form.$gray-dark;
  margin: ($x * -1) ($y * -1);
  margin-inline-end: $x;
  padding: $y $x;
  pointer-events: none;
}



// Media Queries
@mixin media( $size: md, $loop: false )
{
  $value: map-get( form.$breakpoints, $size );
  @if( $size == 'xs' ){
    @if $loop != false {
      @content;
    } @else {
      $value: map-get( form.$breakpoints, sm ) - 1px;
      @media screen and ( max-width: $value ) {
        @content;
      }
    }

  } @else {
    @media screen and ( min-width: $value ) {
      @content;
    }
  }
}



// Flex Property
@mixin flexProperty($width)
{
  flex: 0 0 $width;
  width: $width;
  max-width: $width;
}



// Make grid items
@mixin makeGridItems( $key, $num )
{
  .#{$key}-#{$num} {
    @include flexProperty( math.div(100%, math.div(form.$form-grid-columns, $num)) );
    padding: calc( var(--form-grid-gutter) * .5 );
    margin: 0;
  }
  .#{$key}-#{$num}-center {
    @include flexProperty( math.div(100%, math.div(form.$form-grid-columns, $num)) );
    padding: calc( var(--form-grid-gutter) * .5 );
    margin: 0 auto;
  }
}



@mixin setSvgIcon( $svg: $arrow-right, $color: $base, $size: 1rem )
{
  background-image: func.set-svg( $svg, $color );
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: $size;
  height: $size;
}
