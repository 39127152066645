@charset 'UTF-8';
@use './global' as form;



.form-grid {
  display: flex;
  flex-flow: row wrap;
  margin-top: calc( var(--form-grid-gutter) * -.5 );
  margin-right: calc( var(--form-grid-gutter) * -.5 );
  margin-left: calc( var(--form-grid-gutter) * -.5 );
  margin-bottom: calc( var(--form-grid-gutter) * -.5 );

  // 隣接
  & + & {
    margin-top: calc( var(--form-grid-gutter) * .5 );
  }

  @each $key, $value in form.$breakpoints {
    @include form.media($key, true){
      @for $i from 1 through form.$form-grid-columns {
        @include form.makeGridItems( $key, $i );
      }
    }
  }
}
