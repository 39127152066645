@charset 'UTF-8';
@use './global' as form;



label.selection-label {
  display: flex; // checkbox に高さを与えるため
  margin: 0 auto;
  //height: 100%;

  & + & {
    margin-top: 1rem;
  }

  [type='radio'],
  [type='checkbox'] {

    & + .selection-label-container {
      background-color: form.$gray-100;
      border: 3px solid form.$gray-200;
      border-radius: 8px;
      cursor: pointer;
      color: form.$gray-dark;
      display: flex;
      flex-direction: row;
      align-items: center;
      //height: 100%;
      padding: 1em;
      position: relative;
      transition: background-color form.$base-transition, border form.$base-transition;

      @media(hover:hover){
        &:hover {
          background-color: form.$gray-200;
        }
      }
      &:before {
        content: '';
        background-image: form.set-svg( form.$checkbox, form.$gray-500 );
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        flex: 0 0 auto;
        margin-right: .7em;
        width: 1.5em;
        height: 1.5em;
        transition: background form.$base-transition;
      }
    }

    &:checked + .selection-label-container {
      background-color: form.$gray-100;
      border-color: form.$accent;
      color: form.$gray-dark;
      &:before {
        background-image: form.set-svg( form.$checkbox-checked, form.$base );
      }
    }
  }
  // Radio
  [type='radio'] {
    & + .selection-label-container {
      &::before {
        background-image: form.set-svg(form.$radio, form.$gray-500);
      }
    }
    &:checked + .selection-label-container {
      &::before {
        background-image: form.set-svg(form.$radio-checked, form.$accent);
      }
    }
  }
}



.selection-inner {
  .title {
    font-weight: 700;
  }
  .selection-notes {
    border-top: 1px solid form.$gray-300;
    font-family: sans-serif;
    font-size: .857142em;
    margin-top: 1em;
    padding-top: 1em;
  }
}
