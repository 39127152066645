@charset 'UTF-8';
@use './global/' as *;



.header-wrapper {
  background-color: $white;
  position: relative;
}



.header-container {
  @include makeContainer;
}



.header-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  position: relative;
}



.header-inner-left {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
}



.header-inner-center {
  display: flex;
  justify-content: center;
  flex: 1 1 0;
}



.header-inner-right {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 0;
}



.header-brand {
  margin-bottom: .75rem;
  img {
    display: block;
    width: auto;
    height: 1.25rem;
  }
}

.header-headline {
  color: $gray-800;
  font-size: 1.25em;
  line-height: 1;
  margin-bottom: 0;
  text-align: center;
}



.user-account {
  background-color: $gray-600;
  background-image: set-svg($person, $white);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 66%;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  width: 3rem;
  height: 3rem;
}

.user-account-list {
  $padding-gutter: 1rem;

  display: none;

  .active + & {
    display: block;
  }

  background-color: $white;
  box-shadow: $base-box-shadow-small;
  position: absolute;
  top: 4.5rem;
  right: 0;

  > li {
    &:nth-of-type(n+2){
      border-top: 1px solid $gray-300;
    }

    &.account {
      background-color: $gray-100;
      ul {
        padding: $padding-gutter;
        li {
          font-size: .875em;
          &.facilities {
            font-weight: 500;
          }
        }
      }
    }

    a {
      color: $gray-dark;
      display: block;
      padding: $padding-gutter;
      transition: background-color $base-transition;
      @media(hover: hover){
        &:hover {
          background-color: $gray-200;
        }
      }
    }
  }
}
