@charset 'UTF-8';
@use './global/' as *;



.registration-wrapper {
  background-color: $gray-200;
  display: block;
  padding: 2rem 0;
  position: relative;
  width: 100%;
  height: 100%;
}



.registration-container {
  background-color: $white;
  border-radius: 4px;
  box-shadow: $base-box-shadow-large;
  margin: 0 auto;
  padding: 2rem 1rem;
  position: relative;
  width: clamp(320px, 90vw, 40rem);
  @include media(md){
    padding: 2rem;
  }
}



.registration-header {
  text-align: center;
}



.registration-body {
  h1 {
    color: $uno;
    font-size: 1.25rem;
    margin-bottom: 4em;
    text-align: center;
  }
}
