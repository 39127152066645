@charset 'UTF-8';
@use './global/' as *;



.lead + .list,
.list + .lead,
.lead + .define-list,
.define-list + .lead,
.lead + .table,
.table + .lead,
.lead + .card,
.card + .lead,
.lead + .card-mat,
.card-mat + .lead {
  margin-top: $base-gutter * 1.5;
  @include media(lg){
    margin-top: $base-gutter * 2;
  }
}
