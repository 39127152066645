@charset 'UTF-8';
@use './global/' as *;



h1, h2, h3, h4, h5, h6 {
  font-style: normal;
  line-height: $heading-line-height;
  margin-top: 0;
  text-rendering: optimizeLegibility;
}



h1 {
  font-size: $h1-font-size;
  margin-bottom: $h1-margin-bottom;
}

h2 {
  font-size: $h2-font-size;
  margin-bottom: $h2-margin-bottom;
}

h3 {
  font-size: $h3-font-size;
  margin-bottom: $h3-margin-bottom;
}

h4 {
  font-size: $h4-font-size;
  margin-bottom: $h4-margin-bottom;
}

h5 {
  font-size: $h5-font-size;
  margin-bottom: $h5-margin-bottom;
}

h6 {
  font-size: $h6-font-size;
  margin-bottom: $h6-margin-bottom;
}



// Paragraph
.lead {
  font-size: inherit;
  letter-spacing: $lead-letter-spacing;
  line-height: $lead-line-height;
  text-rendering: optimizeLegibility;
  // .lead spacer
  & + & {
    margin-top: $lead-top-spacer;
  }
  &.notes {
    font-family: sans-serif;
    font-size: .875em;
    padding-left: 1em;
    text-indent: -1em;
    &::before {
      content: '※';
    }
  }
}



// フォントファミリーを日本語に限定する（※）
.mbf {
  font-family: -apple-system, BlinkMacSystemFont, 'Hiragino Sans', 'Original Yu Gothic', 'Yu Gothic', sans-serif !important;
}



// code
pre {
  background-color: $gray-100;
  padding: 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
  code {
    background-color: transparent;
    color: $gray-700;
    font-family: 'SourceHanCodeJP-Regular', monospace;
    font-size: .8em;
    font-weight: bold;
    vertical-align: initial;
  }
}
