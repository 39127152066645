@charset 'UTF-8';

/*
** サイトカラーを使用する場合はサイトで使用している変数を読み込んで
** 下記の変数に割り当ててください
** 例：
** @use '../../module/var/' as 名前空間;
*/

@use '../../global/var/' as global;



// Color
$base  : global.$base !default;
$accent: global.$accent !default;
$uno   : global.$uno !default;
$dos   : global.$dos !default;
$tres  : global.$tres !default;
$cuatro: global.$cuatro !default;
$cinco : global.$cinco !default;

$red   : global.$red !default;
$yellow: global.$yellow !default;
$orange: global.$orange !default;
$green : global.$green !default;
$blue  : global.$blue !default;
$pink  : global.$pink !default;
$purple: global.$purple !default;
$cyan  : global.$cyan !default;

$gray-100 : global.$gray-100 !default;
$gray-200 : global.$gray-200 !default;
$gray-300 : global.$gray-300 !default;
$gray-400 : global.$gray-400 !default;
$gray-500 : global.$gray-500 !default;
$gray-600 : global.$gray-600 !default;
$gray-700 : global.$gray-700 !default;
$gray-800 : global.$gray-800 !default;
$gray-900 : global.$gray-900 !default;
$gray-dark: global.$gray-dark !default;
$black    : global.$black    !default;
$white    : global.$white    !default;

// Base box-shadw
$box-shadow:       0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-small: 0 .125rem .25rem rgba($black, .075) !default;
$box-shadow-large: 0 1rem 3rem rgba($black, .175) !default;

// font-family
$font-family: -apple-system, Robot, 'Helvetica Neue', 'Helvetica', 'Hiragino Sans', 'Noto Sans CJK JP', 'メイリオ', Meiryo, Arial, sans-serif;

// Transition
$base-transition: .2s ease-out;

// Breakpoint
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;



// Font Size
// iOSのフォーカス時のズームを無効にするには16px以上を指定
$form-font-size: 16px;



/**
*** Form Container
**/

// Form Container の max-width
$form-container-max-width: 768px !default;

// Form Container の padding
$form-container-padding: 1em !default;

// Form Container のボーダー
$form-container-border: 1px solid #DDE6ED !default;

// タイトルのバックグラウンドカラー
$title-background-color: $white !default;

// 入力のバックグラウンドカラー
$value-background-color: $white !default;

// 任意と必須
$optional-color: $white !default;
$optional-background-color: #339966 !default;
$required-color: $white !default;
$required-background-color: #b0120a !default;

// input 要素の padding
$input-x-padding: 1em !default;
$input-y-padding: 1em !default;

// Component のボーダー
$component-border-color: $gray-400 !default;
$component-border-width: 3px !default;
$component-border-radius: 8px !default;

// placeholder
$placeholder-color: $gray-600 !default; // フォントカラー
$placeholder-font-size: .875em !default; // フォントサイズ

// form-grid のカラム数
$form-grid-columns: 12 !default;

// グリッド要素同士の空白
$form-grid-gutters: 8px !default;

// 各項目の間隔
$form-field-gutter: 2rem !default;

// チェックボックス & ラジオボタンのマージン
$radio-checked-gutter: .5rem;

// 確認画面を表示するクラス名
$verify-visible-class: '.form-verify-visible';



// Btn
$btn-border-width : 2px;
$btn-border-radius: 2em;
$btn-font-size    : 16px;
$btn-height       : 52px;
$btn-x-padding    : 32px;
$btn-gutter       : 8px;



@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}



// Checkbox
$checkbox: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M6,3h12c1.7,0,3,1.3,3,3v12c0,1.7-1.3,3-3,3H6c-1.7,0-3-1.3-3-3V6C3,4.3,4.3,3,6,3zM6,5C5.4,5,5,5.4,5,6v12c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1V6c0-0.6-0.4-1-1-1H6z"/></svg>') !default;

// Checkbox checked
$checkbox-checked: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M18,3c1.7,0,3,1.3,3,3v12c0,1.7-1.3,3-3,3H6c-1.7,0-3-1.3-3-3V6c0-1.7,1.3-3,3-3H18z M16.5,8L10,14.4L7.5,12c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l3,3c0.3,0.3,0.8,0.3,1.1,0l7-7c0.3-0.3,0.3-0.8,0-1.1C17.2,7.7,16.8,7.7,16.5,8z"/></svg>') !default;

// Radio
$radio: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z M12,3.5c-4.7,0-8.5,3.8-8.5,8.5s3.8,8.5,8.5,8.5s8.5-3.8,8.5-8.5S16.7,3.5,12,3.5z"/></svg>') !default;

// Radio checked
$radio-checked: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z M12,3.5c-4.7,0-8.5,3.8-8.5,8.5s3.8,8.5,8.5,8.5s8.5-3.8,8.5-8.5S16.7,3.5,12,3.5z M12,6c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6S8.7,6,12,6z"/></svg>') !default;

// caret（下）
$caret-down: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><polygon points="2,7.6 3.2,6.4 12,15.2 20.8,6.4 22,7.6 12,17.6 "/></svg>') !default;

// カレンダー
$calendar: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/></svg>') !default;

// Remove
$remove: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17,13H7v-2h10V13z"/></svg>') !default;

// 新規ウインドウ
$open-new-window: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/></svg>') !default;
