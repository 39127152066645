@charset 'UTF-8';
@use './global' as form;



select.select {
  background-color: form.$white;
  background-image: form.set-svg( form.$caret-down, form.$gray-dark );
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  border: form.$component-border-width solid form.$component-border-color;
  border-radius: form.$component-border-radius;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: form.$form-font-size * .875;
  font-weight: 700;
  outline: none;
  padding: form.$input-y-padding 3em form.$input-y-padding 1em;
  position: relative;
  transition: border-color form.$base-transition;
  width: auto;
  &::-moz-focus-inner {
    border: 0;
  }
  // error
  &.error {
    border-color: form.$red;
  }
}
