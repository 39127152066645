@charset 'UTF-8';
@use './global/' as *;



.login-wrapper {
  background-color: $gray-200;
  display: grid;
  place-content: center;
  place-items: center;
  width: 100vw;
  height: 100vh;
}



.login-container {
  background-color: $white;
  border-radius: 4px;
  box-shadow: $base-box-shadow-large;
  padding: 2rem;
  position: relative;
  width: clamp(292px, 90vw, 28rem);
}



.login-header {
  border-bottom: 1px solid $gray-300;
  font-weight: 500;
}



.login-body {
  position: relative;
  h1 {
    font-size: 1.75rem;
    margin: 0 0 1.5em;
  }
}




.login-label {
  font-size: .75rem;
  font-weight: 500;
  margin: 0 0 .5em;
}



.login-btn-wrapper {
  margin: 3rem 0 0;
}

.login-btn-container {
  margin: 0 auto;
  width: 12rem;
  & + & {
    margin-top: 2rem;
  }
}



.login-error-container {
  display: flex;
  justify-content: center;
  padding: 0 2rem;
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
}

.login-error-message {
  animation: fadeIn $base-transition forwards;
  color: $red;
  display: inline-block;
  font-size: .875rem;
  font-weight: 500;
  opacity: 0;
}
