@charset 'UTF-8';
@use './global' as form;
@use 'sass:math';



:root {
  --form-grid-gutter: #{form.$form-grid-gutters};
}



form {
  box-sizing: border-box;

  * {
    box-sizing: border-box;
    margin: 0;
  }
}

select {
  appearance: none;
}



// Utilities
.m0  { margin         : 0 !important; }
.mt0 { margin-top     : 0 !important; }
.mb0 { margin-bottom  : 0 !important; }
.ml0 { margin-left    : 0 !important; }
.mr0 { margin-right   : 0 !important; }
.p0  { padding        : 0 !important; }
.pt0 { padding-top    : 0 !important; }
.pb0 { padding-bottom : 0 !important; }
.pl0 { padding-left   : 0 !important; }
.pr0 { padding-right  : 0 !important; }

$margin-padding-properties: (
  mt: margin-top,
  mb: margin-bottom,
  mr: margin-right,
  ml: margin-left,
  pt: padding-top,
  pb: padding-bottom,
  pr: padding-right,
  pl: padding-left
) !default;

@each $media, $property in $margin-padding-properties {
  @for $i from 1 through 25 {
    .#{$media}#{$i * 4} {
      #{$property}: ( $i * 4 ) + px !important;
    }
  }
}
