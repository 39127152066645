@charset 'UTF-8';
@use './global/' as *;



.inquiry-wrapper {
  background-color: #FBF7F7;
  display: block;
  padding: 2rem 0;
  width: 100%;
  height: 100%;
}



.inquiry-container {
  background-color: $white;
  border-radius: 4px;
  box-shadow: $base-box-shadow-large;
  margin: 0 auto;
  padding: 2rem 1rem;
  position: relative;
  width: clamp(320px, 90vw, 60rem);
  @include media(md){
    padding: 2rem;
  }
}



.inquiry-header {
  margin: 0 0 4rem;
}



.inquiry-headline {
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
}
