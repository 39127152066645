@charset 'UTF-8';
@use './global' as form;



input[type='checkbox'],
input[type='radio'] {
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  margin: 0;
  outline: none;
}



.checkbox-group,
.radio-group {
  display: inline-block;
  margin: form.$radio-checked-gutter * -1;
  // 横並び
  &.row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
}



.label-checkbox,
.label-radio {
  display: flex;
  padding: form.$radio-checked-gutter;

  label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    &::before {
      content: '';
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      margin-right: .125em;
      width: 1.5em;
      height: 1.5em;
      transition: background form.$base-transition;
    }
  }
}



.label-checkbox {
  input[type="checkbox"] {
    + label {
      &::before {
        background-image: form.set-svg( form.$checkbox, form.$gray-dark );
      }
    }
    &:checked + label {
      &::before {
        background-image: form.set-svg( form.$checkbox-checked, form.$base );
      }
    }
  }
}



.label-radio {
  input[type="radio"] {
    + label {
      &::before {
        background-image: form.set-svg( form.$radio, form.$gray-dark );
      }
    }
    &:checked + label {
      &::before {
        background-image: form.set-svg( form.$radio-checked, form.$accent );
      }
    }
  }
}
