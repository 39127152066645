@charset 'UTF-8';
@use './global' as form;
@use 'sass:math';



// 確認ボタン要素の領域
.form-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}



.form-btn-container {
  display: flex;
  flex-direction: column;
  margin: form.$btn-gutter * -1;

  @include form.media(){
    flex-direction: row;
  }

  &.justify-between {
    justify-content: space-between;
  }

  .btn-column {
    flex: 0 0 auto;
    padding: form.$btn-gutter;
    order: 1;
    &.sp-order-first {
      order: 0;
      @include form.media(){
        order: 1;
      }
    }
  }
}



.form-btn {
  background: form.$base;
  border: form.$btn-border-width solid transparent;
  border-radius: form.$btn-border-radius;
  box-shadow: 0px 3px 1px -2px rgba( 0, 0, 0, .2 ),
              0px 2px 2px 0px rgba( 0, 0, 0, .14 ),
              0px 1px 5px 0px rgba( 0, 0, 0, .12 );
  cursor: pointer;
  color: form.$white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: form.$btn-font-size;
  font-weight: 700;
  line-height: form.$btn-height;
  height: calc( form.$btn-height + form.$btn-border-width * 2 );
  outline: none;
  padding: 0 form.$btn-x-padding;
  text-align: center;
  text-decoration: none;
  transition: background-color form.$base-transition, border-color form.$base-transition;
  white-space: nowrap;
  // Hover
  &:hover {
    background: lighten( form.$base, 10% );
    color: form.$white;
  }
  // キャンセルとか
  &.outline {
    background: form.$white;
    border: form.$btn-border-width solid form.$gray-600;
    color: form.$gray-600;
    &:hover {
      background: form.$gray-600;
      color: form.$white;
    }
  }
  // Expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
