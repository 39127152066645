@charset 'UTF-8';
@use './global' as form;



// Input 要素
//
.input {
  background-color: form.$gray-100;
  border: form.$component-border-width solid form.$gray-200;
  border-radius: form.$component-border-radius;
  box-shadow: none;
  color: form.$gray-dark;
  font-size: form.$form-font-size;
  line-height: 1;
  padding: form.$input-x-padding form.$input-y-padding;
  width: 100%;
  transition: border-color form.$base-transition, background-color form.$base-transition;

  // プレースホルダー
  &::placeholder {
    font-size: form.$placeholder-font-size;
    transition: color form.$base-transition;
  }
  &::-moz-placeholder {
    color: form.$placeholder-color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: form.$placeholder-color;
  }
  &::-webkit-input-placeholder {
    color: form.$placeholder-color;
  }
  &::-ms-expand {
    background-color: form.$white;
    border: 0;
  }

  // File input
  &[type="file"] {
    font-size: form.$form-font-size * .875;
    overflow: hidden;

    &:not(:disabled):not([readonly]) {
      cursor: pointer;
    }
  }

  &::file-selector-button {
    @include form.file-selector-button(form.$input-x-padding, form.$input-y-padding);
  }
  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: form.$gray-200;
  }

  &::-webkit-file-upload-button {
    @include form.file-selector-button(form.$input-x-padding, form.$input-y-padding);
  }
  &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: form.$gray-200;
  }

  // フォーカス
  &:focus,
  &:focus:not(:placeholder-shown) {
    background-color: form.$white;
    border-color: form.$accent;
    box-shadow: 0 0 6px 1px rgba( form.$base, .075 );
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }
  // 入力済み
  &:not(:placeholder-shown) {
    background-color: form.$gray-100;
    border: form.$component-border-width solid form.$component-border-color;
  }

  // テキストエリア
  @at-root textarea#{&} {
    font-family: form.$font-family;
    line-height: 1.6;
    height: auto;
    padding: 1em;
    resize: vertical;
    width: 100%;
  }

  & + & {
    margin-top: var(--form-grid-gutter);
  }

  // error
  &.error {
    border: form.$component-border-width solid form.$red;
  }
}
