@charset 'UTF-8';
@use './global' as form;



// エラー表示用
.validate {
  display: none;

  &.valid {
    display: inline-block;
  }

  .error {
    animation: fadeIn form.$base-transition;
    //background-color: form.$red;
    //border-radius: 4px;
    box-shadow: form.$box-shadow-small;
    color: form.$red;
    display: inline-block;
    font-size: .875em;
    font-weight: 700;
    //padding: .25em 1em;
    position: relative;
  }
  // 下部
  &.lower {
    margin-top: 8px;
    margin-left: 0;
    .error {
      &::before {
        content: '';
        border: 4px solid transparent;
        border-bottom: 4px solid form.$red;
        position: absolute;
        bottom: 100%;
        left: 10%;
        width: 0;
        height: 0;
      }
    }
  }
}



.h-adr {
  display: block;
}



.form-notes {
  font-family: sans-serif;
  font-size: .875em;
  padding-left: 1em;
  text-indent: -1em;
  &::before {
    content: '※';
  }
}



// 通常のラベル
label.item {
  border-left: 6px solid form.$base;
  color: form.$gray-700;
  display: block;
  font-size: .875em;
  font-weight: 700;
  margin-bottom: .8em;
  padding-left: .6em;
}



.input-parent {
  display: flex;
  align-items: center;
  position: relative;
  & + & {
    margin-top: var(--form-grid-gutter);
  }
  .grid,
  .form-grid {
    flex: 1;
  }
}



// サブタイトルあり
.input-container {
  & + & {
    margin-top: 2rem;
  }
}

label.request {
  border-left: 6px solid form.$base;
  color: form.$gray-600;
  display: block;
  font-size: .875em;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: .5em;
  padding-left: .5em;
}



// カレンダーアイコンの表示
.icon-calendar {
  display: flex;
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 3em;
  transform: translateY(-50%);
  &::before {
    content: '';
    background-image: form.set-svg( form.$calendar, form.$gray-400);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
  }
  // Delete アイコンが無いとき
  &.no-delete {
    right: 1rem;
  }
}



// カレンダーの入力値を削除するボタン
.delete-calendar {
  background-image: form.set-svg( form.$remove, form.$gray-600 );
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  margin-left: 8px;
  width: 1.5em;
  height: 1.5em;
  transition: background-image form.$base-transition;
  @media(hover:hover){
    &:hover {
      background-image: form.set-svg( form.$remove, form.$gray-400 );
    }
  }
}



// プライバシーポリシーに同意する
.privacy-checkbox-wrapper {
  display: flex;
  margin-top: 4rem;
}

.privacy-checkbox-container {
  display: inline-block;
  margin: 0 auto;
  //width: 100%;
  //max-width: form.$form-container-max-width;
}

.privacy-link {
  margin-top: .5em;
  text-align: right;
  a {
    color: form.$gray-dark;
    display: inline-flex;
    align-items: center;
    font-size: .875em;
    &::before {
      content: '';
      @include form.setSvgIcon( form.$open-new-window, form.$gray-dark, 1.125em );
    }
  }
}



// 上記フォームの必要事項にご記入の上
.description-container {
  border: 1px solid form.$gray-400;
  font-size: .875rem;
  margin-top: 4rem;
  padding: 1.5em;
}



// display none
.display-none {
  display: none;
}
